@font-face {
  font-family: Visuelt;
  src: url('/visuelt-regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: Visuelt;
  src: url('/visuelt-medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: Visuelt;
  src: url('/visuelt-italic.otf') format('opentype');
  font-style: italic;
  font-weight: 400;
}

$border-size: 2px;
$border: $border-size solid #000;

.app {
  min-height: 100vh;
  font-family: Visuelt;

  header {
    outline: 2px solid #000;
    padding: 58px 80px;

    h1 {
      margin: 0;
      font-weight: normal;
    }
  }

  main {
    font-size: 22px;
    height: calc(100vh - 157px);
    display: flex;
    flex: 1;

    .column {
      flex: 1;
      padding: 70px 80px;
      max-height: 100%;
      overflow: auto;

      &+.column {
        border-left: 2px solid #000;
      }
    }

    ul {
      list-style: none;
      padding-left: 0;
      margin: 0;
    }

    p {
      margin: 0;
    }
  }
}

::-webkit-scrollbar {
  width: 25px;
}

::-webkit-scrollbar-thumb {
  border: 2px solid #000;
  border-right: none;
}

@media screen and (max-width: 768px) {
  .app {

    header {
      padding: 30px 35px;

      h1 {
        font-size: 20px;
      }
    }

    main {
      display: block;
      font-size: 16px;

      .column {
        padding: 33px 35px;
        max-height: none;

        &+.column {
          border-left: none;
          border-top: 2px solid #000;
        }

        .section .client .client__work {
          font-size: 14px;
        }
      }
    }
  }
}

.section {

  .section__title {
    font-weight: 500;
    text-decoration: underline;
  }

  &+.section {
    margin-top: 35px;
  }
}

.client {
  margin-top: 35px;

  .client__name {
    font-weight: 500;
  }

  .client__description {

  }

  .client__work {
    font-style: italic;
    font-size: 20px;
  }
}